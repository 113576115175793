<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";
import { useReferencesStore } from "@/store/references";
import testimonialsCover from "@/assets/image/testimonials/testimonials-cover.png";

const route = useRoute();
const store = useDefaultStore();
const refStore = useReferencesStore();
const legalTestimonialVideoBool = ref(false);

definePage({
	name: "LegalTestimonials",
});

useHead({
	title: $t("app.legalTestimonials.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{
			name: "title",
			content: $t("app.legalTestimonials.pageTitle"),
		},
		{ name: "description", content: $t("app.legalTestimonials.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.legalTestimonials.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.legalTestimonials.metaDesc"),
		},
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});
</script>

<template lang="pug">
top-cover(
	:cover="testimonialsCover",
	:title="$t('app.legalTestimonials.title1')"
)
template(v-for="(ref, index) in refStore.testimonialsListLegal", :key="ref.id")
	testimonial-comp(
		:id="ref.id",
		:theme="ref.id % 2 === 0 ? 'lighter' : 'dark'",
		:direction="index % 2 === 0 ? 'reverse' : 'normal'",
		:cover="ref.video",
		:content="ref.text",
		@testimonial-cover-click="legalTestimonialVideoBool = true"
	)
.legal-testimonials-video-popover(
	:class="{ 'legal-testimonials-video-popover-show': legalTestimonialVideoBool, 'legal-testimonials-video-popover-hide': !legalTestimonialVideoBool }",
	@click="legalTestimonialVideoBool = false"
)
	iframe.legal-testimonials-video-popover-item(
		title="Apatinska pivara - Solarna Elektrana 10000 kWp",
		:src="legalTestimonialVideoBool ? 'https://www.youtube.com/embed/Wn1leF8sJJA' : ''",
		width="1800",
		height="1010",
		frameborder="0",
		allowfullscreen="allowfullscreen"
	)
	.legal-testimonials-video-popover-close(
		@click="legalTestimonialVideoBool = false"
	)
		|
		i-fa-xmark.legal-testimonials-video-popover-close-icon
</template>

<style lang="scss" scoped>
.legal-testimonials-video-popover {
	opacity: 0;

	&.legal-testimonials-video-popover-hide {
		opacity: 0;
		display: none;
	}

	&.legal-testimonials-video-popover-show {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
		transition: opacity 3s ease;
		opacity: 1;

		.legal-testimonials-video-popover-item {
			max-width: 80%;
			max-height: 80%;
			height: unset;
			aspect-ratio: 100 / 56;
		}

		.legal-testimonials-video-popover-close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 20px;
			cursor: pointer;
			font-size: 22px;

			:deep() {
				svg {
					path {
						color: rgb(255, 255, 255);
					}
				}
			}

			&:hover {
				:deep() {
					svg {
						path {
							opacity: 0.8;
						}
					}
				}
			}
		}
	}
}
</style>
