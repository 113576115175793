<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const route = useRoute();
const store = useDefaultStore();

definePage({
	name: "RegularReferences",
});

useHead({
	title: $t("app.references.regular.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.references.regular.pageTitle") },
		{ name: "description", content: $t("app.references.regular.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.references.regular.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.references.regular.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});
</script>

<template lang="pug">
references-comp(:blogType="'regular'")
</template>

<style lang="scss" scoped>
.page {
	padding: 20px;
	height: 100%;
	width: 100%;
}
</style>
