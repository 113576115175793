<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import { debounce } from "@/assets/js/helpers";
import type { ITag } from "src/pages/index/blog/[slug]/index.vue";
import { $t } from "@/i18n";

definePage({
	name: "EditTags",
});

const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
const tagsList = ref<ITag[] | null>(null);
const selectedTagId = ref<number | null>(null);
const changeTagText = ref("");
const changeTagBool = ref(false);
const newTagText = ref("");
const deleteModalBool = ref(false);
const modalItemId = ref(-1);
const searchTagText = ref("");

useHead({
	title:
		$t("app.admin.tags.editTitle") +
		" | " +
		$t("app.adminPanel") +
		" | " +
		store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.admin.tags.editTitle") },
		{ name: "description", content: store.META_DATA.NAME_DESCRIPTION },
		// { name: "keywords", content: store.META_DATA.NAME_KEYWORDS },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS_NO_INDEX },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content:
				$t("app.admin.tags.editTitle") +
				" | " +
				$t("app.adminPanel") +
				" | " +
				store.APP_NAME,
		},
		{ property: "og:description", content: store.META_DATA.NAME_DESCRIPTION },
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

// Get tags

async function getTags() {
	tagsList.value = await store.getTags(searchTagText.value);
}

const processChange = debounce(getTags, 500, false);

// Delete tag

async function deleteTag(tag_id: number) {
	const params = {
		tag_id,
		sid: storeSession.userToken,
	};
	try {
		const res = await api.tagsRemove(params);
		console.warn(res.data);
		tagsList.value = await store.getTags();
		deleteModalBool.value = false;
		toast.openToastSuccess($t("app.admin.tags.deleteSuccess"));
		return true;
	} catch (err: any) {
		console.warn(err.message);
		deleteModalBool.value = false;
		toast.openToastError($t("app.errorMessage"));
		return false;
	}
}

// Modal (delete)

const modalConfig = {
	title: $t("app.admin.deleteModalTitle"),
	isOverlayTransparent: true,
	// handleDraggable: false,
};

const modalStyle = {
	// fontSize: "1.5rem",
	paddingBottom: "18px",
	border: "1px solid #ccc",
	backgroundColor: "#fff",
	fontFamily: "'Galano Grotesque', sans-serif",
};

function openDeleteModal(id: number) {
	modalItemId.value = id;
	deleteModalBool.value = true;
}

// Edit tag

function toEditTag(tag: ITag) {
	changeTagBool.value = true;
	selectedTagId.value = tag.tag_id;
	changeTagText.value = tag.tag_name;
}

function cancelEdit() {
	changeTagBool.value = false;
	selectedTagId.value = null;
}

async function editTag(tag_id: number) {
	const params = {
		tag_id,
		tag_name: changeTagText.value,
		sid: storeSession.userToken,
	};
	try {
		const res = await api.tagsEdit(params);
		console.warn(res.data);
		toast.openToastSuccess($t("app.admin.tags.editSuccess"));
		tagsList.value = await store.getTags();
		changeTagBool.value = false;
		selectedTagId.value = null;
		return true;
	} catch (err: any) {
		console.warn(err.message);
		toast.openToastError($t("app.errorMessage"));
		return false;
	}
}

// Create tag

async function createTag(tag_name: string) {
	const params = {
		tag_name,
		sid: storeSession.userToken,
	};
	try {
		const res = await api.tagsAdd(params);
		console.warn(res.data);
		toast.openToastSuccess($t("app.admin.tags.createSuccess"));
		tagsList.value = await store.getTags();
		changeTagBool.value = false;
		selectedTagId.value = null;
		newTagText.value = "";
		return true;
	} catch (err: any) {
		console.warn(err.message);
		toast.openToastError($t("app.errorMessage"));
		return false;
	}
}

onMounted(async () => {
	await getTags();
});
</script>

<template lang="pug">
modern-modal(
	v-if="deleteModalBool",
	:config="modalConfig",
	:form-style="modalStyle",
	@close-modal="deleteModalBool = false"
)
	template(#content)
		.admin-delete-modal-title {{ $t("app.admin.tags.deleteModalContent") }}
		.admin-submit
			button(@click="deleteTag(modalItemId)") {{ $t("app.submit") }}
.admin-main-wrapper
	.admin-title {{ $t("app.admin.tags.create") }}
	.admin-add-item-wrapper
		input.admin-input(v-model="newTagText", placeholder="Name")
		.admin-submit
			button(:disabled="!newTagText", @click="createTag(newTagText)") {{ $t("app.submit") }}
	.admin-title {{ $t("app.admin.tags.editTitle") }}
	.admin-edit-seacrh-wrapper
		input.admin-input(
			v-model="searchTagText",
			type="text",
			:placeholder="store.placeholders.postSearch",
			@input="processChange"
		)
	.admin-items-wrapper(v-if="tagsList")
		.admin-edit-item-container(v-for="tag in tagsList", :key="tag.tag_id")
			.admin-edit-item-title(v-if="tag.tag_id !== selectedTagId") {{ tag.tag_name }}
			input.admin-input(
				v-if="tag.tag_id === selectedTagId",
				v-model="changeTagText",
				style="display: block"
			)
			.admin-edit-item-buttons
				i-fa-trash.edit-posts-icon(
					v-if="tag.tag_id !== selectedTagId",
					@click="openDeleteModal(tag.tag_id)"
				)
				i-fa-pen-to-square.edit-posts-icon(
					v-if="tag.tag_id !== selectedTagId",
					@click="toEditTag(tag)"
				)
				i-fa-rectangle-xmark.edit-posts-icon(
					v-if="changeTagBool && tag.tag_id === selectedTagId",
					@click="cancelEdit"
				)
				i-fa-square-check.edit-posts-icon(
					v-if="changeTagBool && tag.tag_id === selectedTagId",
					:disabled="!changeTagText",
					@click="editTag(tag.tag_id)"
				)
</template>

<style lang="scss" scoped>
.admin-edit-item-title {
	@include ellipsis;

	-webkit-line-clamp: 1;
	height: 38.4px;
}
</style>
