<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

definePage({
	name: "LegalBlogPage",
	redirect: "/pravna-lica/akademija",
});

const store = useDefaultStore();

useHead({
	title:
		$t("app.blog.title") +
		" | " +
		$t("app.blogTypeLegal") +
		" | " +
		store.APP_NAME,
	meta: [
		{
			name: "title",
			content: $t("app.blog.title") + " " + $t("app.blogTypeLegal"),
		},
		{
			name: "description",
			content: store.META_DATA.NAME_DESCRIPTION,
		},
		// { name: "keywords", content: "blog, " + store.META_DATA.NAME_KEYWORDS },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content:
				$t("app.blog.title") +
				" | " +
				$t("app.blogTypeLegal") +
				" | " +
				store.APP_NAME,
		},
		{
			property: "og:description",
			content: store.META_DATA.PROPERTY_DESCRIPTION,
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});
</script>

<template lang="pug">
.blog-page-wrapper ...
</template>

<style lang="scss" scoped></style>
