<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import type { ICategory } from "src/components/sideBar/CategoriesBar.vue";
import { useToastStore } from "@/store/toast";
import { $t } from "@/i18n";
import { debounce } from "@/assets/js/helpers";

definePage({
	name: "EditCategories",
});

const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
const categoriesList = ref<ICategory[] | null>(null);
const changeCategoryBool = ref(false);
const selectedCategoryId = ref<number | null>(null);
const changeCategoryText = ref("");
const newCategoryText = ref("");
const selectedRootId = ref<number | null>(-1);
const deleteModalBool = ref(false);
const modalItemId = ref(-1);
const searchCategoryText = ref("");

useHead({
	title:
		$t("app.admin.categories.editTitle") +
		" | " +
		$t("app.adminPanel") +
		" | " +
		store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.admin.categories.editTitle") },
		{ name: "description", content: store.META_DATA.NAME_DESCRIPTION },
		// { name: "keywords", content: store.META_DATA.NAME_KEYWORDS },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS_NO_INDEX },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content:
				$t("app.admin.categories.editTitle") +
				" | " +
				$t("app.adminPanel") +
				" | " +
				store.APP_NAME,
		},
		{ property: "og:description", content: store.META_DATA.NAME_DESCRIPTION },
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

// Get tags

async function getCategories() {
	categoriesList.value = await store.getCategories(searchCategoryText.value);
}

const processChange = debounce(getCategories, 400, false);

// delete category

async function deleteCategory(cat_id: number) {
	const params = {
		cat_id,
		sid: storeSession.userToken,
	};
	try {
		const res = await api.categoriesRemove(params);
		console.warn(res.data);
		deleteModalBool.value = false;
		categoriesList.value = await store.getCategories();
		toast.openToastSuccess($t("app.admin.categories.removeSuccess"));
		return true;
	} catch (err: any) {
		deleteModalBool.value = false;
		console.warn(err.message);
		toast.openToastError($t("app.errorMessage"));
		return false;
	}
}

// Modal (delete)

const modalConfig = {
	title: $t("app.admin.deleteModalTitle"),
	isOverlayTransparent: true,
};

const modalStyle = {
	paddingBottom: "18px",
	border: "1px solid #ccc",
	backgroundColor: "#fff",
	fontFamily: "'Galano Grotesque', sans-serif",
};

function openDeleteModal(id: number) {
	modalItemId.value = id;
	deleteModalBool.value = true;
}

// Edit category

function toEditCategory(category: ICategory) {
	changeCategoryBool.value = true;
	selectedCategoryId.value = category.cat_id;
	changeCategoryText.value = category.cat_name;
}

function cancelEdit() {
	changeCategoryBool.value = false;
	selectedCategoryId.value = null;
}

async function editCategory(
	type: string,
	category: ICategory,
	parent?: number,
) {
	let params = {};
	if (type == "name") {
		params = {
			cat_id: category.cat_id,
			cat_name: changeCategoryText.value,
			sid: storeSession.userToken,
		};
	} else if (type == "parent") {
		params = {
			cat_id: category.cat_id,
			cat_id_parent: parent,
			sid: storeSession.userToken,
		};
	}

	try {
		const res = await api.categoriesEdit(params);
		console.warn(res.data);
		toast.openToastSuccess($t("app.admin.categories.editSuccess"));
		categoriesList.value = await store.getCategories();
		changeCategoryBool.value = false;
		selectedCategoryId.value = null;
		return true;
	} catch (err: any) {
		console.warn(err.message);
		toast.openToastError($t("app.errorMessage"));
		return false;
	}
}

// Create category

async function createCategory() {
	let params = {};
	params =
		selectedRootId.value == -1
			? {
					cat_name: newCategoryText.value,
					sid: storeSession.userToken,
				}
			: {
					cat_name: newCategoryText.value,
					cat_id_parent: selectedRootId.value,
					sid: storeSession.userToken,
				};

	try {
		const res = await api.categoriesAdd(params);
		console.warn(res.data);
		toast.openToastSuccess($t("app.admin.categories.createSuccess"));
		categoriesList.value = await store.getCategories();
		changeCategoryBool.value = false;
		selectedCategoryId.value = null;
		newCategoryText.value = "";
		selectedRootId.value = -1;
		return true;
	} catch (err: any) {
		console.warn(err.message);
		toast.openToastError($t("app.errorMessage"));
		return false;
	}
}

onMounted(async () => {
	await getCategories();
});
</script>

<template lang="pug">
modern-modal(
	v-if="deleteModalBool",
	:config="modalConfig",
	:form-style="modalStyle",
	@close-modal="deleteModalBool = false"
)
	template(#content)
		.admin-delete-modal-title {{ $t("app.admin.categories.deleteModalContent") }}
		.admin-submit
			button(@click="deleteCategory(modalItemId)") {{ $t("app.submit") }}
.admin-main-wrapper
	.admin-title {{ $t("app.admin.categories.create") }}
		.admin-add-item-wrapper
			input.admin-input(v-model="newCategoryText", placeholder="Name")
			select.admin-input(v-model="selectedRootId", style="display: block")
				option(value=-1) {{ $t("app.admin.categories.noParent") }}
				template(v-for="category in categoriesList", :key="category.cat_id")
					option(:value="category.cat_id") {{ category.cat_name }}
			.admin-submit
				button(:disabled="!newCategoryText", @click="createCategory") {{ $t("app.submit") }}
	.admin-title {{ $t("app.admin.categories.editTitle") }}
	template(v-if="categoriesList")
		.admin-edit-seacrh-wrapper
			input.admin-input(
				v-model="searchCategoryText",
				type="text",
				:placeholder="store.placeholders.postSearch",
				@input="processChange"
			)
		.admin-items-wrapper(
			v-for="category in categoriesList",
			:key="category.cat_id"
		)
			.admin-edit-item-container
				div
					.admin-edit-item-title(v-if="category.cat_id !== selectedCategoryId") {{ category.cat_name }}
					input.admin-input(
						v-if="category.cat_id === selectedCategoryId",
						v-model="changeCategoryText",
						style="display: block"
					)
				.admin-edit-item-buttons
					i-fa-trash.edit-posts-icon(
						v-if="category.cat_id !== selectedCategoryId",
						@click="openDeleteModal(category.cat_id)"
					)
					i-fa-pen-to-square.edit-posts-icon(
						v-if="category.cat_id !== selectedCategoryId",
						@click="toEditCategory(category)"
					)
					i-fa-rectangle-xmark.edit-posts-icon(
						v-if="changeCategoryBool && category.cat_id === selectedCategoryId",
						@click="cancelEdit"
					)
					i-fa-square-check.edit-posts-icon(
						v-if="changeCategoryBool && category.cat_id === selectedCategoryId",
						:disabled="!changeCategoryText",
						@click="editCategory('name', category)"
					)
			.admin-subcategory-container
				.admin-edit-item-container.edmin-edit-categories-subcategory-item(
					v-for="subCategory in category.child_categories",
					:key="subCategory.cat_id"
				)
					.admin-edit-subcategory-title(
						v-if="subCategory.cat_id !== selectedCategoryId"
					)
						| {{ subCategory.cat_name }}
					input.admin-input(
						v-if="subCategory.cat_id === selectedCategoryId",
						v-model="changeCategoryText",
						style="display: block"
					)
					.admin-edit-item-buttons
						i-fa-trash.edit-posts-icon(
							v-if="subCategory.cat_id !== selectedCategoryId",
							@click="openDeleteModal(subCategory.cat_id)"
						)
						i-fa-circle-up.edit-posts-icon(
							v-if="subCategory.cat_id !== selectedCategoryId",
							@click="editCategory('parent', subCategory, -1)"
						)
						i-fa-pen-to-square.edit-posts-icon(
							v-if="subCategory.cat_id !== selectedCategoryId",
							@click="toEditCategory(subCategory)"
						)
						i-fa-rectangle-xmark.edit-posts-icon(
							v-if="changeCategoryBool && subCategory.cat_id === selectedCategoryId",
							@click="cancelEdit"
						)
						i-fa-square-check.edit-posts-icon(
							v-if="changeCategoryBool && subCategory.cat_id === selectedCategoryId",
							:disabled="!changeCategoryText",
							@click="editCategory('name', subCategory)"
						)
</template>

<style lang="scss" scoped>
.admin-subcategory-container {
	width: 100%;

	.admin-edit-item-container {
		width: unset;
	}
	.edmin-edit-categories-subcategory-item {
		margin-left: 24px;
	}

	.admin-edit-subcategory-title {
		border: 1px solid rgba(255, 0, 0, 0);

		@include ellipsis;

		-webkit-line-clamp: 1;
		height: 38.4px;

		&:focus {
			border: 1px solid rgba(255, 0, 0, 0);
			outline: none;
		}
	}
}

.admin-submit {
	margin-top: 0;
}

.admin-edit-item-title {
	@include ellipsis;

	-webkit-line-clamp: 1;
	height: 38.4px;
}
</style>
