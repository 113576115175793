<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import type { IPost } from "src/pages/index/blog/[slug]/index.vue";
import { useRoute } from "vue-router";
import type { ICategory } from "src/components/sideBar/CategoriesBar.vue";
import type { ITag } from "src/pages/index/blog/[slug]/index.vue";
import type { TFilePickerChange } from "src/components/shared/fileUpload/FilePicker.vue";
import { $t } from "@/i18n";
import noImage from "@/assets/image/no-image.svg";

definePage({
	name: "EditPost",
});

const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
const postItem = ref<IPost | null>(null);
const categoriesList = ref<ICategory[] | null>(null);
const pst_id = ref(-1);
const imgBool = ref(false);
const tmbBool = ref(false);
const tagsList = ref<ITag[] | null>(null);
const catBool = ref(false);
const selectedCategories = ref<number[]>([]);
const errorMsgBool = ref(false);
const errorMsgText = ref("");
const errorMsgTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const coverImagePreviewUrl = ref("");
const thumbImagePreviewUrl = ref("");
const buttonActive = ref(true);

useHead({
	title:
		$t("app.admin.editPostTitle") +
		" | " +
		$t("app.adminPanel") +
		" | " +
		store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.admin.editPostTitle") },
		{ name: "description", content: store.META_DATA.NAME_DESCRIPTION },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS_NO_INDEX },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content:
				$t("app.admin.editPostTitle") +
				" | " +
				$t("app.adminPanel") +
				" | " +
				store.APP_NAME,
		},
		{ property: "og:description", content: store.META_DATA.NAME_DESCRIPTION },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

const form = reactive({
	pst_id: -1,
	post_date: "",
	title: "",
	meta_title: null as string | null,
	description: null as string | null,
	read_time: null as number | null,
	slug: "",
	image: null as File | null,
	image_alt: null as string | null,
	thumb: null as File | null,
	thumb_alt: null as string | null,
	categories: null as number[] | null,
	status: 1,
	blog_type: -1,
	content: "",
	tags: [] as ITag[] | null,
	sid: storeSession.userToken,
});

const setFormValues = (postData: IPost, catMap: number[]) => {
	form.pst_id = pst_id.value;
	form.post_date = postData.pst_post_date;
	form.title = postData.pst_title;
	form.meta_title = postData.pst_meta_title;
	form.description = postData.pst_description;
	form.read_time = postData.pst_read_time;
	form.slug = postData.pst_slug;
	form.image = null;
	form.image_alt = postData.img_alt;
	form.thumb = null;
	form.thumb_alt = postData.tmb_alt;
	form.categories = catMap;
	form.status = postData.sta_id;
	form.blog_type = postData.blt_id;
	form.content = replaceDivWithP(postData.pst_content);
	form.tags = JSON.parse(postData.tags);
};

const replaceDivWithP = (html: string) => {
	return html.replace(/<div/g, "<p").replace(/<\/div>/g, "</p>");
};

const isValidEditForm = computed(() => {
	const hasFields = !!(
		form.title &&
		form.post_date &&
		form.slug &&
		form.status &&
		form.blog_type &&
		form.content
	);
	if (form.blog_type == -1) return false;
	if (hasFields && buttonActive.value) return true;
	return false;
});

async function getPostIdFromPath() {
	pst_id.value = (route.params as unknown as { id: number }).id;
	await getPost(pst_id.value);
}

// Get post by id

async function getPost(idFromUrl: number) {
	const params = {
		pst_id: idFromUrl,
		sid: storeSession.userToken,
	};
	try {
		const res = await api.postsGetById(params);
		postItem.value = res.data.data[0];

		if (postItem.value) {
			// categories map and set seleceted categories

			const postCategories: ICategory[] =
				postItem.value.categories && postItem.value.categories.length > 0
					? store.allPostCategories(postItem.value.categories)
					: [];
			const categoriesMap = ref<number[] | null>(null);
			categoriesMap.value = postCategories.map(function (cat) {
				return cat.cat_id;
			});
			selectedCategories.value = categoriesMap.value;

			// handle images

			coverImagePreviewUrl.value = postItem.value.img_image ?? noImage;

			thumbImagePreviewUrl.value = postItem.value.tmb_image ?? noImage;

			setFormValues(postItem.value, categoriesMap.value);
		}

		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

async function onClickEditPost() {
	buttonActive.value = false;
	document.body.classList.add("cursor-wait");
	// slug check
	const slugBool = await store.slugCheck(
		form.slug,
		form.blog_type,
		pst_id.value,
	);
	if (!slugBool) {
		errorMsgText.value = $t("app.admin.errorMsg.slugExists");
		errorMsgBool.value = true;
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
		return false;
	}
	if (!store.slugRegex.test(form.slug)) {
		errorMsgText.value = $t("app.admin.errorMsg.slug");
		errorMsgBool.value = true;
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
		return false;
	}

	if (form.tags && form.tags.length < 1) {
		form.tags = null;
	}

	const formData = new FormData();
	for (const key in form) {
		if (
			key !== "image" &&
			key !== "thumb" &&
			key !== "categories" &&
			key !== "tags" &&
			key !== "thumb_alt" &&
			key !== "image_alt" &&
			key !== "read_time" &&
			key !== "content" &&
			key !== "post_date" &&
			key !== "tags"
		) {
			formData.append(key, (form as Record<string, any>)[key]);
		}
	}
	if (form.tags && form.tags.length < 1) {
		const tagsMap = form.tags.map(function (tag) {
			return tag.tag_id;
		});

		for (const tagId of tagsMap) {
			formData.append("tags", String(tagId));
		}
	}
	if (form.post_date) {
		formData.append("post_date", store.formatDatepickerDate(form.post_date));
	}
	if (form.image) {
		formData.append("image", form.image);
	}
	if (form.thumb) {
		formData.append("thumb", form.thumb);
	}
	if (catBool.value && form.categories && form.categories.length > 0) {
		for (const categoryId of (form as Record<string, any>).categories) {
			formData.append("categories", categoryId);
		}
	} else if (catBool.value) {
		formData.append("categories", "-1");
	}
	if (form.image != null || form.image_alt != null) {
		formData.append("image_alt", (form as Record<string, any>).image_alt);
	}

	if (form.thumb != null || form.thumb_alt != null) {
		formData.append("thumb_alt", (form as Record<string, any>).thumb_alt);
	}

	if (form.content) {
		const characterCount = form.content.length;
		const readTime = Math.ceil(characterCount / 5 / 200);

		formData.append("content", form.content);
		formData.append("read_time", String(readTime));
	}

	try {
		const res = await api.postsEdit(formData);
		toast.openToastSuccess($t("app.admin.editPostSuccess"));
		console.warn(res.data);
	} catch (err: any) {
		console.warn(err.message);
		errorMsgBool.value = true;
		errorMsgText.value = $t("app.errorMessage");
	} finally {
		document.body.classList.remove("cursor-wait");
		buttonActive.value = true;
	}
}

// categories and tags selection

function handleCategorySelection(
	categoryObj: ICategory,
	parentCategoryId?: number,
) {
	if (selectedCategories.value.includes(categoryObj.cat_id)) {
		selectedCategories.value = selectedCategories.value.filter(
			(id) => id !== categoryObj.cat_id,
		);

		if (
			categoryObj.child_categories &&
			categoryObj.child_categories.length > 0
		) {
			for (const child of categoryObj.child_categories) {
				if (selectedCategories.value.includes(child.cat_id)) {
					selectedCategories.value = selectedCategories.value.filter(
						(id) => id !== child.cat_id,
					);
				}
			}
		}
	} else {
		if (parentCategoryId) {
			if (selectedCategories.value.includes(parentCategoryId)) {
				selectedCategories.value.push(categoryObj.cat_id);
			} else {
				selectedCategories.value.push(parentCategoryId, categoryObj.cat_id);
			}
		} else {
			selectedCategories.value.push(categoryObj.cat_id);
		}
	}

	form.categories =
		selectedCategories.value.length === 0 ? null : selectedCategories.value;

	catBool.value = true;
}

// add and remove image

function handleCoverFileChange(event: TFilePickerChange) {
	form.image = event.files ? event.files[0] : null;
	form.image_alt = null;
	imgBool.value = false;

	if (event.files && event.files?.length > 0) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			coverImagePreviewUrl.value = reader.result as string;
		});
		reader.readAsDataURL(event.files[0]);
	} else {
		coverImagePreviewUrl.value = "";
	}
}

function handleThumbFileChange(event: TFilePickerChange) {
	form.thumb = event.files ? event.files[0] : null;
	form.thumb_alt = null;
	tmbBool.value = false;

	if (event.files && event.files?.length > 0) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			thumbImagePreviewUrl.value = reader.result as string;
		});
		reader.readAsDataURL(event.files[0]);
	} else {
		thumbImagePreviewUrl.value = "";
	}
}

watchEffect(() => {
	void getPostIdFromPath();
});

watch(errorMsgBool, (newValue, oldValue) => {
	if (newValue) {
		errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
		errorMsgTimeout.value = setTimeout(() => {
			errorMsgBool.value = false;
		}, 5000);
	} else {
		errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
	}
});

onMounted(async () => {
	categoriesList.value = await store.getSsCategories();
	tagsList.value = await store.getTags();
});
</script>

<template lang="pug">
.admin-main-wrapper
	.admin-title
		span {{ $t("app.admin.editPostTitle") }}
	.admin-items-wrapper
		.group
			label {{ $t("app.admin.postData.title") }}*
				span &nbsp;({{ form.title?.length }}/255)
			input(v-model="form.title", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.metaTitle") }}
				span &nbsp;({{ form.meta_title?.length }}/255)
			input(v-model="form.meta_title", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.description") }}*
			input(v-model="form.description", type="text", placeholder="Description")
		.group
			label {{ $t("app.admin.postData.postDate") }}*
			modern-date-picker(v-model="form.post_date")
		.group
			label {{ $t("app.admin.postData.slug") }}*
				span &nbsp;({{ form.slug?.length }}/255)
			input(v-model="form.slug", type="text", maxlength="255")
		.group
			label {{ $t("app.admin.postData.coverImage") }}
			.admin-image-choose-wrapper
				file-picker(
					title="Choose Cover Image",
					placeholder="Select an image file",
					:multiple=false,
					@change="handleCoverFileChange"
				)
					template(#buttonLabel)
						span.admin-file-picker-button-title {{ $t("app.admin.chooseImage") }}
				.admin-image-preview-wrapper(
					:style="{ 'background-image': 'url(' + coverImagePreviewUrl + ')' }"
				)
		.group
			label {{ $t("app.admin.postData.coverAlt") }}
				span &nbsp;({{ form.image_alt ? form.image_alt.length : 0 }}/255)
			input(
				v-model="form.image_alt",
				type="text",
				:disabled="imgBool",
				maxlength="255"
			)
		.group
			label {{ $t("app.admin.postData.thumbImage") }}
			.admin-image-choose-wrapper
				file-picker(
					title="Choose Cover Image",
					placeholder="Select an image file",
					:multiple=false,
					@change="handleThumbFileChange"
				)
					template(#buttonLabel)
						span.admin-file-picker-button-title {{ $t("app.admin.chooseImage") }}
				.admin-thumb-preview-wrapper(
					v-if="thumbImagePreviewUrl",
					:style="{ 'background-image': 'url(' + thumbImagePreviewUrl + ')' }"
				)
				.admin-thumb-preview-no-image-wrapper(
					v-else,
					:style="{ 'background-image': 'url(' + noImage + ')' }"
				)
		.group
			label {{ $t("app.admin.postData.thumbAlt") }}
				span &nbsp;({{ form.thumb_alt ? form.thumb_alt.length : 0 }}/255)
			input(
				v-model="form.thumb_alt",
				type="text",
				:disabled="tmbBool",
				maxlength="255"
			)
		.group
			label {{ $t("app.admin.postData.categories") }}
			div
				.categories-wrapper(
					v-for="category in categoriesList",
					:key="category.cat_id"
				)
					input(
						:id="category.cat_name",
						style="display: none",
						type="checkbox",
						:value="category.cat_id",
						:checked="selectedCategories.includes(category.cat_id)",
						@change="handleCategorySelection(category)"
					)
					label.category-label(:for="category.cat_name")
						span(
							:class="{ 'checkbox-selected': selectedCategories.includes(category.cat_id) }"
						) {{ category.cat_name }}
					template(
						v-for="subCategory in category.child_categories",
						:key="subCategory.cat_id"
					)
						input(
							:id="subCategory.cat_name",
							style="display: none",
							type="checkbox",
							:value="subCategory.cat_id",
							:checked="selectedCategories.includes(subCategory.cat_id)",
							@change="handleCategorySelection(subCategory, category.cat_id)"
						)
						label.sub-category-label(:for="subCategory.cat_name")
							span(
								:class="{ 'checkbox-selected': selectedCategories.includes(subCategory.cat_id) }"
							) {{ subCategory.cat_name }}
		.group
			label {{ $t("app.admin.postData.status") }}*
			select(v-model.number="form.status")
				option(
					v-for="(value, index) in store.POST_STATUSES",
					:key="index",
					:value="index",
					:selected="index === 1"
				) {{ value }}
		.group
			label {{ $t("app.admin.postData.blogType") }}*
			select(v-model.number="form.blog_type")
				option(value=-1, disabled) {{ $t("app.admin.postForm.selectBlogType") }}
				option(:value="store.REGULAR_BLOG.BLOG_TYPE_ID") {{ store.REGULAR_BLOG.BLOG_TYPE_NAME }}
				option(:value="store.LEGAL_BLOG.BLOG_TYPE_ID") {{ store.LEGAL_BLOG.BLOG_TYPE_NAME }}
		.group
			label {{ $t("app.admin.postData.tags") }}
				multiselect-form(
					v-model="form.tags",
					searchable,
					mode="tags",
					:options="tagsList || []",
					label="tag_name",
					value-prop="tag_id",
					placeholder="Search tags"
				)
		.group
			label {{ $t("app.admin.postData.content") }}*
				.admin-form-quill-editor-wrapper
					quill-editor.quill-editor(
						v-model:content="form.content",
						contentType="html",
						theme="snow",
						:options="store.toolbarOptions",
						placeholder="Sadržaj..."
					)
		.admin-submit
			button(:disabled="!isValidEditForm", @click="onClickEditPost")
				span {{ $t("app.submit") }}
			div(v-if="errorMsgBool") {{ errorMsgText }}
</template>

<style lang="scss" scoped>
.new-post-cover-image {
	height: 100px;
	width: 100px;
	border: 1px solid black;
	background-position: center;
	background-size: cover;
}

// Categories start

.categories-wrapper {
	overflow: visible;
	display: flex;
	flex-wrap: wrap;
}

.category-label,
.sub-category-label {
	cursor: pointer;

	span {
		border-radius: 4px;
		padding: 4px 6px;
		border: 1px solid $form-input-border-light;

		&:hover {
			background-color: $selected-item;
		}
	}
}

.category-label {
	display: block;
	width: 100%;
	margin: 10px 0;
}

.sub-category-label {
	font-style: italic;
	color: rgb(102, 102, 102);
	margin-bottom: 8px;
	margin-left: 20px;
}

.checkbox-selected {
	background-color: $selected-item;
}

// Categories end

select,
input[type="text"],
input[type="date"],
input[type="number"],
input[type="checkbox"],
input[type="textbox"] {
	width: 100%;
	padding: 8px;
	border: 1px solid $form-input-border-light;
	border-radius: 4px;
	box-sizing: border-box;
	font-size: 16px;

	&:focus {
		border: 1px solid $form-input-border-light;
		outline: none;
	}
}

// ******* Date picker start

.modern-date-picker {
	:deep() {
		.dp__main {
			.dp__input {
				width: 100%;
				padding: 8px;
				border: 1px solid $form-input-border-light;
				border-radius: 4px;
				box-sizing: border-box;
				font-size: 16px;
				padding-left: var(--dp-input-icon-padding);
			}
		}
	}
}

// Date picker end

// Multiselect form start

.multiselect-form {
	$multiselect-height: 40px;

	display: flex;
	width: 100%;

	:deep() {
		.multiselect {
			border-bottom: unset;
			outline: unset;
			height: 100%;

			.multiselect-wrapper {
				padding: 0;
				min-height: $multiselect-height;
				outline: 1px solid $form-input-border-light;
				border-radius: 4px;
				font-size: 16px;

				.multiselect-tags {
					margin: 0;
					padding: 0;
					.multiselect-tag {
						background-color: rgb(204, 204, 204);
						margin-left: 8px;
						color: unset;
						font-weight: unset;
					}

					.multiselect-tags-search-wrapper {
						min-height: $multiselect-height;
						margin-left: 8px;
					}
				}
			}
		}

		.multiselect-search {
			padding: 8px;
			border: none;
			outline: none;
		}

		.multiselect-single-label {
			padding: 8px;
		}

		.multiselect-placeholder {
			padding: 8px;
		}

		.multiselect-options {
			max-height: 200px;
			overflow-y: auto;
		}

		.multiselect-dropdown {
			border: 1px solid $form-input-border-light;
			border-top: none;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			background-color: rgb(255, 255, 255);
			z-index: 999;
		}

		.custom-caret {
			display: none;
		}
	}

	&.no-caret {
		.custom-caret {
			display: none;
		}
	}

	&.no-border {
		.multiselect {
			border: none;
		}
	}

	&.fixed-drop {
		.multiselect-dropdown {
			position: fixed;
			top: 100%;
			left: 0;
			z-index: 999;
		}
	}
}

// Multiselect form end
</style>
