import { defineStore } from "pinia";
import { useWindowSize } from "@vueuse/core";
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";
import {
	setLocalStorageReac,
	setSessionStorageReac,
	getSessionStorageReac,
} from "@/assets/js/helpers";
import type { ICategory } from "@/components/sideBar/CategoriesBar.vue";
import { useSessionStore } from "@/store/session";
import { $t } from "@/i18n";
import type { IPost } from "src/pages/index/blog/[slug]/index.vue";
import dayjs from "dayjs";
import logo from "@/assets/image/og-logo.png";

type TUserData = {
	name: string;
};

// Format post date

function formatPostDate(date: string): string {
	date = DateInstance.getFormattedDate(date, "DD.MM.YYYY HH:mm", false);
	return date;
}

function formatDatepickerDate(date: string): string {
	const localDate = dayjs(date);
	const utcDate = localDate.utc();
	return utcDate.format("YYYY-MM-DD HH:mm:ss");
}

// Get posts

interface IGetPostsParams {
	with_draft?: boolean | null;
	with_deleted?: boolean | null;
	with_published?: boolean | null;
	offset?: number;
	limit?: number;
	search?: string | null;
	blog_type?: number | null;
	removed_only?: boolean | null;
	slug?: string;
	search_year?: number | null;
	tags?: number[];
	root_only?: boolean;
	exclude_locale?: string;
	sid?: string;
	latest?: boolean;
	future_dates?: boolean;
	landing_page?: boolean;
	academy_page?: boolean;
}

async function getPosts(params: IGetPostsParams): Promise<IPost[] | string> {
	try {
		const res = await api.postsGet(params);
		const blogPosts = res.data.data;
		if (params.landing_page == true) {
			setSessionStorageReac("homePageAcademyDataSuncica", blogPosts);
		}
		return blogPosts;
	} catch (err: any) {
		console.warn(err.message);
		const blogPosts = "wrong params";
		return blogPosts;
	}
}

// Get posts for home page and set homePageAcademyData
/*
async function getLsPostsForHomePage(): Promise<IPost[] | null> {
	const storageKey = "homePageAcademyDataSuncica";
	// Try to fetch posts from localStorage
	const initialRes = getLocalStorageReac(storageKey, true);
	const blogPosts = ref<IPost[] | null>(initialRes.value as IPost[] | null);
	// If no posts found in localStorage, fetch them from the API
	if (blogPosts.value == null) {
		await getPosts({ limit: 7, landing_page: true });
		// After fetching, attempt to update from localStorage again
		const updatedRes = getLocalStorageReac(storageKey, true);
		blogPosts.value = updatedRes.value as IPost[] | null;
	}
	// Return the blog posts or null if still not found
	return blogPosts.value;
}
	*/

async function getSsPostsForHomePage(): Promise<IPost[] | null> {
	const res = getSessionStorageReac("homePageAcademyDataSuncica", true);
	const blogPosts = ref<IPost[] | null>(null);
	blogPosts.value = res.value as IPost[] | null;

	if (blogPosts.value == null) {
		await getPosts({ limit: 7, landing_page: true });
		const res = getSessionStorageReac("homePageAcademyDataSuncica", true);
		blogPosts.value = res.value as IPost[] | null;
	}
	return blogPosts.value;
}

// Get categories and set categoriesData

async function getCategories(search?: string) {
	try {
		const res = await api.categoriesGet({ search });

		if (!search) {
			setSessionStorageReac("categoriesDataSuncica", res.data.data);
		}

		return res.data.data;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

// Get cached categories

async function getSsCategories(): Promise<ICategory[] | null> {
	const res = getSessionStorageReac("categoriesDataSuncica", true);
	const categoriesList = ref<ICategory[] | null>(null);
	categoriesList.value = res.value as ICategory[] | null;

	if (categoriesList.value == null) {
		await getCategories();
		const res = getSessionStorageReac("categoriesDataSuncica", true);
		categoriesList.value = res.value as ICategory[] | null;
	}
	return categoriesList.value;
}

// Get tags (by search) and set tagsData

async function getTags(search?: string) {
	try {
		const res = await api.tagsGet({ search });

		if (!search) {
			setSessionStorageReac("tagsData", res.data.data);
		}

		return res.data.data;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

// edit post

interface IEditPostsParams {
	pst_id: number;
	title?: string;
	description?: string;
	post_date?: Date | null;
	read_time?: number | null;
	// author?: string;
	slug?: string;
	image?: File | null;
	image_alt?: string;
	image_name?: string;
	thumb?: File | null;
	thumb_alt?: string;
	thumb_name?: string;
	categories?: number[] | null;
	locale?: string;
	status?: number | null;
	content?: string;
	image_remove?: boolean;
	thumb_remove?: boolean;
	edit_note?: string | null;
	blog_type?: number;
	tags?: number[] | null;
}

async function editPosts(params: IEditPostsParams) {
	try {
		const res = await api.postsEdit(params);
		console.warn(res.data);
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

// Array of categories and subcategories

function allPostCategories(categories: ICategory[]): ICategory[] {
	const result: ICategory[] = [];
	for (const category of categories) {
		result.push(category);
		if (category.child_categories && category.child_categories.length > 0) {
			result.push(...category.child_categories);
		}
	}
	return result;
}

// Get post by categories

interface IPostsByCategoriesParams {
	blog_type: number;
	categories: number;
	search_year?: number | null;
	future_dates?: boolean;
}

async function getPostsByCategories(
	params: IPostsByCategoriesParams,
): Promise<string | IPost[]> {
	try {
		const res = await api.postsGetByCategories(params);
		return res.data.data;
	} catch (err: any) {
		console.warn(err.message);
		return "error";
	}
}

// Get post by tags

interface IPostsByTagsParams {
	blog_type: number;
	tags: number | number[] | null;
	search_year?: number | null;
	future_dates?: boolean;
}

async function getPostsByTags(
	params: IPostsByTagsParams,
): Promise<string | IPost[]> {
	try {
		const res = await api.postsGetByTags(params);
		return res.data.data;
	} catch (err: any) {
		console.warn(err.message);
		return "error";
	}
}

// Check slug

async function slugCheck(slug: string, blogType: number, exclude_id?: number) {
	const params = {
		slug,
		blogType,
		exclude_id,
	};
	const res = await getPosts(params);
	if (res == "wrong params") {
		return false;
	} else if (res.length > 0) {
		return false;
	}
	return true;
}

// Post form placeholders

const placeholders = {
	title: $t("app.admin.postData.title"),
	description: $t("app.admin.postData.description"),
	// author: $t("app.admin.postData.author"),
	slug: $t("app.admin.postData.slug"),
	coverAlt: $t("app.admin.postData.coverAlt"),
	coverName: $t("app.admin.postData.coverName"),
	thumbAlt: $t("app.admin.postData.thumbAlt"),
	thumbName: $t("app.admin.postData.thumbName"),
	tagsSearch: $t("app.admin.tags.search"),
	parentPostSearch: $t("app.admin.postForm.parentPostSearch"),
	content: $t("app.admin.postData.content"),
	postSearch: $t("app.postSearch"),
};

// Format slug

function formatSlug(s: string): string {
	const w = s.split("-");
	for (let i = 0; i < w.length; i++) {
		w[i] =
			i === 0
				? w[i].charAt(0).toUpperCase() + w[i].slice(1).toLowerCase()
				: w[i].toLowerCase();
	}
	const result = w.join(" ");
	return result;
}

// Solar calculator

const roofArea = ref<number | null>(null);
const mountingSide = ref<string | null>(null);
const roofingMaterial = ref<string | null>(null);
const electricityConsumption = ref<number | null>(null);
const consumptionPercentage = ref<number | null>(null);
const sizeLimit = ref(false);

// Formula for calculating the size of the system based on consumption (kWp):
const formula1 = computed(() => {
	if (electricityConsumption.value && formula3.value !== "NaN") {
		const res = electricityConsumption.value / formula3.value;
		return parseFloat(res.toFixed(0));
	} else {
		return "NaN";
	}
});

// Formula for calculating capacity based on roof area (kWp):
const formula2 = computed(() => {
	return roofArea.value ? roofArea.value / 5 : "NaN";
});

// Annual savings on 1 kWh consumption
const formula3 = computed(() => {
	switch (mountingSide.value) {
		case "s": {
			return 1200;
		}
		case "n": {
			return 850;
		}
		case "e-w": {
			return 1050;
		}
		default: {
			return "NaN";
		}
	}
});

// Savings in consumption on an annual basis for the entire power plant (kWh)
const formula4 = computed(() => {
	const rss = calcRecommendedSystemSize(formula1.value, formula2.value);
	return formula3.value != "NaN" && rss != "NaN" ? rss * formula3.value : "NaN";
});

// Recommended system size
function calcRecommendedSystemSize(
	val1: number | string,
	val2: number | string,
) {
	let res: number | string = "";
	if (typeof val1 === "number" && typeof val2 === "number") {
		res = Math.min(val1, val2);
	} else if (typeof val1 === "number") {
		res = val1;
	} else if (typeof val2 === "number") {
		res = val2;
	} else {
		res = "NaN";
	}
	if (typeof res === "number" && res > 10.8) {
		// 	sizeLimit.value = true;
		return 10;
	}
	return typeof res === "number" ? Math.round(res) : "NaN";
}

// Cost of the recommended system (EUR)
function recommendedSystemPrice() {
	const rs = calcRecommendedSystemSize(formula1.value, formula2.value);
	let res = null;
	if (rs == "NaN") {
		return "NaN";
	} else {
		res = rs * 850;
		return res.toFixed(2);
	}
}

// Total annual savings on the electricity bill
const annualSaving = computed(() => {
	return formula4.value == "NaN" ? "NaN" : formula4.value * 0.1;
});

// Payback period of the system (years)
const paybackPeriod = computed(() => {
	const rsp = recommendedSystemPrice();
	return annualSaving.value != "NaN" && rsp != "NaN"
		? Math.round(parseFloat(rsp) / annualSaving.value)
		: "NaN";
});

// Total savings over the lifespan of the solar power plant
const totalSavings = computed(() => {
	const res = annualSaving.value == "NaN" ? "NaN" : 25 * annualSaving.value;
	return res == "NaN" ? "NaN" : addThousandSeparator(res.toFixed(2));
});

// Necessary dimensions of the roof for installing a solar power plant
const necessaryDimensions = computed(() => {
	const rs = calcRecommendedSystemSize(formula1.value, formula2.value);
	return rs == "NaN" ? "NaN" : rs * 5;
});

function addThousandSeparator(num: number | string) {
	const parts = num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return parts.join(",");
}

export const useDefaultStore = defineStore("main", () => {
	const { width } = useWindowSize();
	const counter = ref(0);
	const isMobileToggled = ref(false);
	const userToken = ref("");
	const userData = ref<TUserData | null>();
	const storeSession = useSessionStore();
	// const blogPosts = ref<IPost[] | null | string>(null);
	const blogPostsList = ref<IPost[] | null>(null);
	const isReference = ref(false);
	const modalCalculatorFormBool = ref(false);
	// const modalLimitNoteBool = ref(false);
	// const modalLimitNoteBool = computed(() =>

	// let res = calcRecommendedSystemSize(formula1, formula2);
	// if(typeof.res === 'number'){
	// 	return res <
	// }

	// calcRecommendedSystemSize(formula1, formula2) < 760

	// );

	const isMobileSize = computed(() => width.value < 760);
	const isMobileView = computed(
		() => isMobileSize.value && isMobileToggled.value,
	);

	watch(isMobileSize, (val) => {
		if (!val) {
			isMobileToggled.value = false;
		}
	});

	async function setupLogin(payload: Parameters<typeof api.authLogin>[0]) {
		try {
			const res = await api.authLogin(payload);
			setupSession(res.data.data);
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}

	function setupSession(token: string, data?: TUserData) {
		// userToken.value = token;
		// userData.value =
		// 	data || {
		// 		name: "Nemesis Nom",
		// 	} ||
		// 	null;
	}

	//

	const urlLocale = ref("");
	const REGULAR_TYPE_PATH = "/fizicka-lica";
	const LEGAL_TYPE_PATH = "/pravna-lica";
	const REGULAR_BLOG = {
		BLOG_TYPE_ID: 2,
		BLOG_TYPE_PATH: "",
		BLOG_TYPE_NAME: "Fizicka lica",
	};
	const LEGAL_BLOG = {
		BLOG_TYPE_ID: 1,
		BLOG_TYPE_PATH: "/pravna",
		BLOG_TYPE_NAME: "Pravna lica",
	};
	const validBlogTypes = [
		REGULAR_BLOG.BLOG_TYPE_PATH,
		LEGAL_BLOG.BLOG_TYPE_PATH,
	];
	const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
	const APP_NAME = "Sunčica Solar";
	const META_DATA = {
		NAME_DESCRIPTION:
			"Ugradnja Solarnih Sistema, solarni paneli, kontroleri, invertori, akumulatori, regulatori,nosači panela.Možete kupiti odmah po najpovoljnijim cenama.",
		NAME_KEYWORDS:
			"Solarni paneli, solarni kontroleri, invertori, akumulatori,solarna rasveta, solarni sistemi, toplotne pumpe",
		NAME_ROBOTS: "index, follow",
		NAME_ROBOTS_NO_INDEX: "noindex, nofollow",
		NAME_AUTHOR: APP_NAME,
		PROPERTY_TITLE: "Sunčica solar",
		PROPERTY_DESCRIPTION:
			"Ugradnja Solarnih Sistema, solarni paneli, kontroleri, invertori, akumulatori, regulatori,nosači panela.Možete kupiti odmah po najpovoljnijim cenama.", // length < 120
		LEGAL_PROPERTY_TITLE: "Sunčica solar",
		LEGAL_PROPERTY_DESCRIPTION:
			"Ugradnja Solarnih Sistema, solarni paneli, kontroleri, invertori, akumulatori, regulatori,nosači panela.Možete kupiti odmah po najpovoljnijim cenama.",
		PROPERTY_IMAGE: logo,
		// random image
	};
	const POST_LOCALES = ["sr", "en", "de"];
	const POST_STATUSES = {
		1: "Draft",
		2: "Published",
	};
	const activeCardRegular = ref(2);
	const activeCardLegal = ref(7);
	const partnersKey = ref(1);
	// regex
	// const slugRegex = /^[a-z\d-čćžšđ]+$/i;
	const slugRegex = /^[a-z\d-čćžšđ\s%.]+$/i;
	const textRegex = /^[-\w\s.,!?()]+$/;
	const authorRegex = /^[-\w\s]+$/;
	const contentRegex = /^(?!.*<\/script>).+$/;
	const canonicalEl: any = null;

	const toolbarOptions = {
		theme: "snow",
		modules: {
			toolbar: [
				[{ size: ["small", false, "large", "huge"] }],
				["bold", "italic", "underline", "strike"],
				["blockquote", "code-block"],
				["link", "image", "video", "formula"],
				[{ color: [] }, { background: [] }],
				[{ script: "super" }, { script: "sub" }],
				[{ indent: "-1" }, { indent: "+1" }],
				// [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
				[{ header: [1, 2, 3, 4, 5, 6, false] }],
				// [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
				// ['direction', { align: [] }],
				[{ direction: "rtl" }],
				["clean"],
			],
		},
		placeholder: "Sadržaj",
		readOnly: false,
		clipboard: {
			matchVisual: false,
		},
	};

	return {
		counter,
		isMobileToggled,
		isMobileView,
		userData,
		setupLogin,
		urlLocale,
		REGULAR_BLOG,
		BASE_URL,
		LEGAL_BLOG,
		validBlogTypes,
		getPosts,
		formatPostDate,
		getCategories,
		APP_NAME,
		META_DATA,
		POST_LOCALES,
		POST_STATUSES,
		getTags,
		getSsCategories,
		editPosts,
		allPostCategories,
		getPostsByCategories,
		getPostsByTags,
		formatDatepickerDate,
		slugRegex,
		textRegex,
		authorRegex,
		contentRegex,
		placeholders,
		slugCheck,
		REGULAR_TYPE_PATH,
		LEGAL_TYPE_PATH,
		formatSlug,
		roofArea,
		mountingSide,
		isMobileSize,
		electricityConsumption,
		consumptionPercentage,
		getSsPostsForHomePage,
		roofingMaterial,
		formula1,
		formula2,
		calcRecommendedSystemSize,
		recommendedSystemPrice,
		annualSaving,
		paybackPeriod,
		totalSavings,
		addThousandSeparator,
		necessaryDimensions,
		isReference,
		sizeLimit,
		modalCalculatorFormBool,
		toolbarOptions,
		activeCardRegular,
		activeCardLegal,
		partnersKey,
		canonicalEl,
		blogPostsList,
	};
});
