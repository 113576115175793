<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { useReferencesStore } from "@/store/references";

const refStore = useReferencesStore();
const route = useRoute();
const store = useDefaultStore();
const referenceSlug = ref("");

definePage({
	name: "RegularReference",
});

const refItem = computed(() => {
	return refStore.referencesList.find(
		(reference) =>
			reference.slug === referenceSlug.value &&
			reference.blt_id === store.REGULAR_BLOG.BLOG_TYPE_ID &&
			reference.path === "reference",
	);
});

function setMeta() {
	if (!refItem.value) return;

	useHead({
		title: `${refItem.value.pageTitle}` + " | " + store.APP_NAME,
		meta: [
			{ name: "title", content: refItem.value.pageTitle },
			{ name: "description", content: refItem.value.description },
			// { name: "keywords", content: store.META_DATA.NAME_KEYWORDS },
			{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
			{ name: "author", content: store.META_DATA.NAME_AUTHOR },
			{
				property: "og:title",
				content: `${refItem.value.pageTitle}` + " | " + store.APP_NAME,
			},
			{ property: "og:description", content: refItem.value.description },
			{ property: "og:image", content: refItem.value.thumb },
			{ property: "og:site_name", content: store.APP_NAME },
			{ property: "og:type", content: "article" },
		],
	});
}

async function getReferenceFromPath() {
	referenceSlug.value = (route.params as { slug: string }).slug;

	setMeta();
}

onMounted(async () => {
	await getReferenceFromPath();
});
</script>

<template lang="pug">
reference-comp(:blogType="'regular'")
</template>

<style lang="scss" scoped></style>
